import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { menuBar } from "../../store/menuBarSlice";
import Dropdown from "react-bootstrap/Dropdown";
import { imageURL } from "../../axios";

import menuIcon from "../../assets/images/menu.svg";
import "./logout.css";

function Logout() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState("");
  const { theme } = useSelector((store) => store.theme);
  const [image, setImage] = useState(menuIcon);

  const res = localStorage.getItem("userData");
  const data = JSON.parse(res);

  useEffect(() => {
    function setInfo() {
      if (data) {
        setFirstName(data.firstName);
        if (data.image) {
          let img = `${imageURL}users/` + data.image;
          setImage(img);
        }
      }
    }
    setInfo();
    console.log("image", image);
  }, []);
  function menuHandler() {
    dispatch(menuBar());
  }
  function handleLogout() {
    localStorage.removeItem("token");
    localStorage.removeItem("userData");
    navigate("/");
  }
  return (
    <div className="d-flex justify-content-end align-items-center">
      <Dropdown>
        <Dropdown.Toggle
          // className="watch-dropdown3 me-2 p-1"
          className="watch-dropdown3 me-2"
          style={{ width: "auto", height: "auto", padding: "5px 10px" }}
        >
          <img
            src={image}
            alt="icon"
            style={{ width: "30px", height: "30px", borderRadius: "50%" }}
          />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={handleLogout}>Log out</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <img
        onClick={menuHandler}
        className="d-md-none d-sm-flex justify-content-center"
        src={menuIcon}
        alt="icon"
        style={{ width: "25%", cursor: "pointer" }}
      />
    </div>
  );
}

export default Logout;
