import React, { useState, useEffect } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useSelector, useDispatch } from "react-redux";
import { themeToggler } from "../../store/themeSlice";
import { menuBar } from "../../store/menuBarSlice";
import { twoFAToggler } from "../../store/twoFactAuth";
import { updateMerchant , inviteCollaborator } from "../../store/updateMerchantSlice";
import { imageURL } from "../../axios";
import Logout from "../Logout/logout";
import Tabs from "../Tabs/tabs";
import Swal from "sweetalert2";
import "./settings.css";

import uploadIcon from "../../assets/images/upload-icon.svg";
import updateIcon from "../../assets/images/check_ring_round.svg";

function Settings() {
  const fileTypes = ["JPG", "PNG", "GIF"];
  const { theme } = useSelector((store) => store.theme);
  const res = localStorage.getItem("userData");
  const data = JSON.parse(res);
  const [imageUrl, setImageUrl] = useState("");
  const [user_id, setUser_id] = useState("");
  const [twoFA, setTwoFA] = useState(false);
  const [image, setImage] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const token = localStorage.getItem("token");
  const [isLoading, setIsLoading] = useState(true);

  const [collaboratorEmail, setCollaboratorEmail] = useState("");
  const [colFirstName, setColFirstName] = useState("");
  const [colLastName, setColLastName] = useState("");
  const [emailStatus, setEmailStatus] = useState({
   loading: false,
   success: false,
   error: null,
   responseError:null
  });

  const dispatch = useDispatch();

  useEffect(() => {
    var mode = localStorage.getItem("mode");
    if (mode) {
      dispatch(themeToggler(mode));
    }
  }, []);

  useEffect(() => {
    setUser_id(data._id);
    setTwoFA(data.is2Factor);
    setEmail(data.email);
    setFirstName(data.firstName);
    setLastName(data.lastName);
    if (data.image) {
      let img = `${imageURL}users/` + data.image;
      setImageUrl(img);
    }
    setIsLoading(false);
  }, []);

  function twoFAHandler() {
    console.log("twoFAHANDLER");
    if (twoFA) {
      let type = "false";
      setTwoFA(false);
      dispatch(twoFAToggler({ type, user_id }));
    } else {
      let type = "true";
      setTwoFA(true);
      dispatch(twoFAToggler({ type, user_id }));
    }
  }

  function themeHander(theme) {
    if (theme === "light") {
      dispatch(themeToggler("dark"));
    } else {
      dispatch(themeToggler("light"));
    }
  }

  async function handleUpdate() {
    if (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Image size is too large.",
        confirmButtonColor: "#4182EB",
      });
      setTimeout(() => {
        setIsLoading(false);
      }, [2000]);
    } else {
      let formData = new FormData();
      formData.append("image", image);
      formData.append("firstName", firstName);
      formData.append("lastName", lastName);
      formData.append("email", email);
      dispatch(updateMerchant({ token, formData }));
      setTimeout(() => {
        setIsLoading(false);
      }, [1000]);
    }
  }

  const inviteCollaboratorFunc = async () => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check if the entered email is valid
    if (!emailRegex.test(collaboratorEmail)) {
      // Email is not valid, handle the error (e.g., show a message to the user)
      setEmailStatus({
        isLoading: false,
        success: false,
        error: "Invalid email address",
      });
      return; // Stop further execution
    }else {
      const results = await dispatch(inviteCollaborator({
        token,
        payload: {
          firstName: colFirstName,
          lastName: colLastName,
          _email: collaboratorEmail,
          _merchantEmail:data.email
        }
      })).unwrap();

      setColFirstName("")
      setColLastName("")
      setCollaboratorEmail("")

      if (!results) {
         console.log("Email Not Sent")
        setEmailStatus({
          isLoading: false,
          success: false,
          error: null,
          // responseError: results.error
        });
        return;
      }
    }


    // Email is valid, proceed with sending the invitation
    // Add your invitation logic here
  };

  return (
    <div className="d-flex flex-row ">
      <Tabs />
      <div className="w-100">
        <div
          className="d-flex justify-content-between pt-3 ps-3 pb-2"
          style={{
            background: "#FFFFFF",
            border: "1px solid #E2E2E2",
            borderRadius: "5px",
          }}
        >
          <div className={`settings-page-heading`}>Settings</div>
          <Logout />
        </div>
        <div className={`container-fluid bg-${theme} main-layout`}>
          {isLoading ? (
            <div className="d-flex justify-content-center p-5">
              {theme === "light" ? (
                <div className="spinner-border text-dark" role="status">
                  {/* <span className="visually-hidden">Loading...</span> */}
                </div>
              ) : (
                <div className="spinner-border text-light" role="status">
                  {/* <span className="visually-hidden">Loading...</span> */}
                </div>
              )}
            </div>
          ) : (
            <div>
              <div className="d-flex flex-sm-row flex-column">
                <div
                  className="col-sm-5 col-11 p-lg-4 p-3 m-3"
                  style={{ background: "#fff", borderRadius: "10px" }}
                >
                  <div className="settings-form-heading pb-2">
                    PERSONAL DETAILS
                  </div>
                  <div
                    className="mb-3"
                    style={{
                      border: "1px solid #E2E2E2",
                      borderRadius: "120px",
                    }}
                  ></div>
                  <div className="settings-form-text mb-2">Profile Photo</div>
                  <div className="d-flex flex-column align-items-center fileDrag pt-4 pb-4 mb-3">
                    <FileUploader
                      handleChange={(file) => {
                        if (file.size > 1000000) {
                          setImage(file);
                          setImageUrl(URL.createObjectURL(file));
                          setError("Image Size is too large.");
                        } else {
                          setError(null);
                          setImage(file);
                          setImageUrl(URL.createObjectURL(file));
                        }
                      }}
                      types={fileTypes}
                      children={
                        <div>
                          {imageUrl ? (
                            <div className="d-flex flex-column align-items-center pt-3 pb-3">
                              <img
                                className="w-100"
                                src={imageUrl}
                                alt="img"
                                style={{ maxHeight: "200px" }}
                              />
                            </div>
                          ) : (
                            <div className="d-flex flex-column align-items-center">
                              <img
                                className="w-25 pb-2"
                                src={uploadIcon}
                                alt="img"
                              />
                              <div className="drag-box-text pb-1">
                                Upload an image or drag and drop
                              </div>
                              <div className="drag-box-text2">
                                PNG, JPG, GIF up to 1MB
                              </div>
                            </div>
                          )}
                        </div>
                      }
                    />
                  </div>
                  {error && (
                    <div className="d-flex justify-content-center error-field ">
                      {error}
                    </div>
                  )}
                  <div className="row d-sm-flex pb-2 pt-2">
                    <div className="d-flex flex-column pb-2 col-sm-6">
                      <span className="settings-form-text pb-2">
                        First Name
                      </span>
                      <input
                        value={firstName}
                        type="text"
                        className="input-box p-2"
                        onChange={(e) => {
                          setFirstName(e.target.value);
                        }}
                      />
                    </div>
                    <div className="d-flex flex-column pb-2 col-sm-6">
                      <span className="settings-form-text pb-2">Last Name</span>
                      <input
                        value={lastName}
                        type="text"
                        className="input-box p-2"
                        onChange={(e) => {
                          setLastName(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-column pb-3">
                    <span className="settings-form-text pb-2">
                      Email Address
                    </span>
                    <input
                      value={email}
                      type="text"
                      className="input-box p-2"
                      readonly
                    />
                  </div>
                  <button
                         disabled={
                          firstName.trim() == "" ||
                          lastName.trim() == "" ||
                          email == "" || ( data.firstName ==firstName  &&  data.lastName == lastName && data.email ==email && image=="" )
                        }
                    onClick={() => {
                      setIsLoading(true);
                      handleUpdate();
                    }}
                    
                    className="update-btn pt-1 pb-1 ps-4 pe-3"
                  >
                    Update
                    <img src={updateIcon} alt="icon" className="ps-1" />
                  </button>
                </div>
                <div className="col-sm-4">
                  <div
                    className="p-lg-4 p-3 m-sm-3 mt-3 ms-3 me-3 mb-5"
                    style={{
                      height: "fit-content",
                      background: "#fff",
                      borderRadius: "10px",
                    }}
                  >
                    <div className="settings-form-heading pb-2">
                      INVITE COLLABORATOR
                    </div>
                    <div
                      className="mb-3"
                      style={{
                        border: "1px solid #E2E2E2",
                        borderRadius: "120px",
                      }}
                    ></div>

                    <div className="row d-sm-flex pb-2 pt-2">
                      <div className="d-flex flex-column pb-2 col-sm-6">
                        <span className="settings-form-text pb-2">
                          First Name
                        </span>
                        <input
                          value={colFirstName}
                          type="text"
                          className="input-box p-2"
                          onChange={(e) => {
                            setColFirstName(e.target.value);
                          }}
                        />
                      </div>
                      <div className="d-flex flex-column pb-2 col-sm-6">
                        <span className="settings-form-text pb-2">
                          Last Name
                        </span>
                        <input
                          value={colLastName}
                          type="text"
                          className="input-box p-2"
                          onChange={(e) => {
                            setColLastName(e.target.value);
                          }}
                        />
                      </div>
                    </div>

                    <div className="d-flex flex-column pb-3">
                      <span className="settings-form-text pb-2">
                        Email Address
                      </span>
                      <input
                        value={collaboratorEmail}
                        type="text"
                        className="input-box p-2 mb-1"
                        onChange={(e) => {
                          setCollaboratorEmail(e.target.value);
                          setEmailStatus({ ...emailStatus, error: null });
                        }}
                        // style={{ border: emailStatus.error ? '1px solid red' : '1px solid #ced4da' }}
                      />
                      {emailStatus.error && (
                        <span className="error-field">{emailStatus.error}</span>
                      )}
                    </div>
                    <button
                      disabled={
                        colFirstName.trim() == "" ||
                        colLastName.trim() == "" ||
                        collaboratorEmail == ""
                      }
                      onClick={() => {
                        inviteCollaboratorFunc();
                      }}
                      className={
                        colFirstName.trim() == "" ||
                        colLastName.trim() == "" ||
                        collaboratorEmail == ""
                          ? "update-btn-disable pt-1 pb-1 ps-4 pe-3"
                          : "update-btn pt-1 pb-1 ps-4 pe-3"
                      }
                      // className="update-btn pt-1 pb-1 ps-4 pe-3"
                    >
                      Invite
                      <img src={updateIcon} alt="icon" className="ps-1" />
                    </button>
                  </div>

                  <div
                    className="p-lg-4 p-3 m-sm-3 mt-3 ms-3 me-3 mb-5"
                    // className="col-sm-4 col-11 p-lg-4 p-3 m-sm-3 mt-3 ms-3 me-3 mb-5"
                    style={{
                      height: "fit-content",
                      background: "#fff",
                      borderRadius: "10px",
                    }}
                  >
                    <div className="settings-form-heading pb-2">
                      SYSTEM SETTINGS
                    </div>
                    <div
                      className="mb-3"
                      style={{
                        border: "1px solid #E2E2E2",
                        borderRadius: "120px",
                      }}
                    ></div>
                    {isLoading ? (
                      <div className="d-flex justify-content-center">
                        <div className="spinner-border text-dark" role="status">
                          {/* <span className="visually-hidden">Loading...</span> */}
                        </div>
                      </div>
                    ) : (
                      <>
                        <div>
                          <div className="d-flex justify-content-between">
                            <div className="settings-form-text">Dark Theme</div>
                            <div className="form-check form-switch">
                              {theme === "light" ? (
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault1"
                                  onChange={() => {
                                    themeHander(theme);
                                  }}
                                />
                              ) : (
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  role="switch"
                                  id="flexSwitchCheckDefault1"
                                  checked
                                  onChange={() => {
                                    themeHander(theme);
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between pt-3">
                          <div className="settings-form-text">
                            Two Factor Authorization
                          </div>
                          <div className="form-check form-switch">
                            {twoFA ? (
                              <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="flexSwitchCheckDefault1"
                                onChange={() => {
                                  twoFAHandler();
                                }}
                                checked
                             
                             
                              />
                            ) : (
                              <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="flexSwitchCheckDefault1"
                                onChange={() => {
                                  twoFAHandler();
                                }}
                              
                               
                              />
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Settings;
