import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { themeToggler } from "../../store/themeSlice";
import { fetchAllWatches } from "../../store/watchSlice";
import { STATUSES } from "../../store/watchSlice";
import ReactPaginate from "react-paginate";
import dateFormat from "dateformat";
import Dropdown from "react-bootstrap/Dropdown";
import WatchDetail from "./view/WatchDetail";
import Tabs from "../Tabs/tabs";
import Add from "./add/add";
import Delete from "./delete/delete";
import DeleteAll from "./deleteAll/deleteAll";
import Edit from "./update/update";
import Logout from "../Logout/logout";
import "./watches.css";
import ProgressiveImage from "react-progressive-graceful-image";
import editIcon from "../../assets/images/edit-icon.svg";
import transferIcon from "../../assets/images/transfer-watch-icon.svg";
import addIcon from "../../assets/images/Add_round.svg";
import search from "../../assets/images/Search_alt.svg";
import deleteIcon from "../../assets/images/delete-icon.svg";
import Sorticon from "../../assets/images/sort.svg";
// import { WatchImage } from "./WatchImage";
import LazyLoad from "react-lazyload";
const placeholder = (
  <img
    src={"https://miro.medium.com/v2/resize:fit:512/0*pyRw1qikTI1eqGm9.gif"}
    alt="placeholder"
    style={{ maxHeight: "50px", maxWidth: "60px" }}
  />
);

function Watches() {
  const [checkedList, setCheckedList] = useState([]);
  const [searchInput, setSearchInput] = useState("null");
  const [deleteId, setDeleteId] = useState({});
  const [updateId, setUpdateId] = useState({});
  const [addButton, setAddButton] = useState(false);
  const [deleteButton, setDeleteButton] = useState(false);
  const [updateButton, setUpdateButton] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalUpdate, setShowModalUpdate] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalDeleteAll, setShowModalDeleteAll] = useState(false);
  const [selectedWatch, setSelectedWatch] = useState({});
  const [openWatchForm, setOpenWatchForm] = useState(false);
  const location = useLocation();
  // const params = useParams();
  // const navigate = useNavigate();
  //const { menu } = useSelector((store) => store.menu);
  const token = localStorage.getItem("token");
  // const userData = localStorage.getItem("userData");

  // Parse user data
  // const parsedUserData = JSON.parse(userData);
  const [pageStatus, setPageStatus] = useState("All Watches");
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const { data: watchesData, status } = useSelector((state) => state.watch);
  const [pageCount, setPageCount] = useState(1);
  const [sortField, setSortField] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("desc")
  const [sortConfig, setSortConfig] = useState({
    field: 'createdAt', // default sort field
    direction: 'desc',  // default sort direction
  });

  const getSearchedParam = () => {
    const searchParams = new URLSearchParams(location.search);
    const name = searchParams.get("name");

    return {
      name: name || "null",
      resultfound: !!name,
    };
  };

  const handleTransferDateClicked = () => {
    setSortConfig({
      field: 'last_trade_date',
      direction: sortConfig.field === 'last_trade_date' ?
          (sortConfig.direction === 'asc' ? 'desc' : 'asc') :
          'desc'
    });
    setSortField("last_trade_date");
    setSortOrder(sortConfig.field === 'last_trade_date' ?
        (sortConfig.direction === 'asc' ? 'desc' : 'asc') :
        'desc'
    );
  };

// Update the click handlers
  const handleAddedDateClicked = () => {
    setSortConfig({
      field: 'createdAt',
      direction: sortConfig.field === 'createdAt' ?
          (sortConfig.direction === 'asc' ? 'desc' : 'asc') :
          'desc'
    });
    setSortField("createdAt");
    setSortOrder(sortConfig.field === 'createdAt' ?
        (sortConfig.direction === 'asc' ? 'desc' : 'asc') :
        'desc'
    );
  };


  useEffect(() => {
    const params = getSearchedParam();
    if (params.resultfound && params.name && searchInput=="null") {
      dispatch(
        fetchAllWatches({ token, pageStatus, page, searchInput: params.name, sortField, sortOrder })
      );
    } else {
      dispatch(fetchAllWatches({ token, pageStatus, page, searchInput, sortField, sortOrder }));
    }
  }, [pageStatus, page, searchInput, sortField, sortOrder]);

  useEffect(() => {
    if (updateButton || deleteButton || addButton) {
      setTimeout(() => {
        dispatch(fetchAllWatches({ token, pageStatus, page, searchInput, sortField, sortOrder }));
      }, [2000]);
    }
  }, [addButton, deleteButton, updateButton]);

  useEffect(() => {
    if (watchesData?.totalItems / 10 === page - 1) {
      setPage(1);
    } else {
      setPageCount(Math.ceil(watchesData?.totalItems / 10));
    }
  }, [watchesData]);

  const { theme } = useSelector((store) => store.theme);

  useEffect(() => {
    var mode = localStorage.getItem("mode");
    if (mode) {
      dispatch(themeToggler(mode));
    }
  }, []);

  if (status === STATUSES.ERROR) {
    return <div className="failed-text">Something went wrong!</div>;
  }
  function handleAdd() {
    if (addButton) setAddButton(false);
    setShowModal(true);
  }
  function handleDelete(_id, image, name, watch_name) {
    if (deleteButton) setDeleteButton(false);
    setDeleteId({ _id, image, watch_name, name });
    setShowModalDelete(true);
  }
  function handleDeleteAll() {
    setShowModalDeleteAll(true);
  }
  // function handleUpdate(_id, image, watch_name, brand_name, clientId, name) {
  //   if (updateButton) setUpdateButton(false);
  //   setUpdateId({ _id, image, watch_name, brand_name, clientId, name });
  //   setShowModalUpdate(true);
  // }

  // function handleTransfer(data) {
  //   setTransferId(data);
  // }

  function handlePageClick(data) {
    setCheckedList([]);
    setPage(data.selected + 1);
  }

  function handleSearchInput(e) {
    setCheckedList([]);
    setPage(1);
    if (e.target.value) {
      setSearchInput(e.target.value);
    } else {
      setSearchInput("null");
    }
  }

  const RenderItems = (watch) => {
    return watch.map((data) => (
      <tr key={data._id} className={`watches-text`}>
        <td>
          <div>{data.watchId}</div>
        </td>
        <td className="col-3">
          <div className="d-flex">
            <div className="d-flex justify-content-center col-2">
              <ProgressiveImage src={data.imageURI} placeholder="">
                {(src, loading) => {
                  return loading ? (
                    placeholder
                  ) : (
                    <LazyLoad once>
                      <img
                        src={src}
                        alt="watch"
                        style={{ maxHeight: "50px", maxWidth: "40px" }}
                      />
                    </LazyLoad>
                  );
                }}
              </ProgressiveImage>
            </div>

            <div
              onClick={() => {
                setSelectedWatch(data);
                setOpenWatchForm(true);
                // handleUpdateMetaData(data, true);
              }}
              // onClick={() => navigate(`/watch-detail/${data._id}`)}
              style={{ cursor: "pointer" }}
              className="pe-2 ps-2 col-10"
            >
              {data.name}
            </div>
          </div>
        </td>
        <td>
          <div>{dateFormat(data.createdAt, "mmm dS, yyyy")}</div>
        </td>
        <td>
          <div>
            {data?.last_trade_date
              ? dateFormat(data.last_trade_date, "mmm dS, yyyy")
              : "-"}
          </div>
        </td>
        <td>
          <div>{data.email}</div>
        </td>
        <td>
          <div className="d-flex">
            {data.isDeleted === true ? (
              <div className={`watch-status disabled`}>Disabled</div>
            ) : data.isCertified === true ? (
              <div className={`watch-status`}>Certified</div>
            ) : (
              <div className={`watch-status2`}>Not Certified</div>
            )}
          </div>
        </td>
        <td>
          {data.isCertified === false && data.isDeleted == false ? (
            <div>
              <Dropdown>
                <Dropdown.Toggle
                  className="watch-dropdown2 d-flex justify-content-start"
                  id="dropdown-basic"
                >
                  {/* <img src={editIcon} alt="icon" className="p-1 me-2" /> */}
                  <img
                    src={transferIcon}
                    alt="icon"
                    className="p-1"
                    style={{ background: "#4182EB", borderRadius: "50%" }}
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {/* <Dropdown.Item
                    className="watches-text"
                    onClick={() =>
                      handleUpdate(
                        data.serialNumber,
                        data.image,
                        data.watchName,
                        data.brandName,
                        data.clientId,
                        data.name
                      )
                    }
                  >
                    Edit
                  </Dropdown.Item> */}
                  {/* <Dropdown.Item
   
                     // onClick={() =>
                     //   handleTransfer(
                     //     data._id,
                     //     data.ownerFirstName,
                     //     data.ownerLastName,
                     //     data.watchName
                     //   )
                     // }
                     >
                       Transfer
                     </Dropdown.Item> */}
                  <Dropdown.Item
                    className="watches-text"
                    onClick={() =>
                      handleDelete(
                        data._id,
                        data.imageURI,
                        data.name,
                        data.email
                      )
                    }
                  >
                    Delete
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          ) : (
            <img src={editIcon} alt="icon" className="p-1 me-2" />
          )}
        </td>
      </tr>
    ));
  };

  return (
    <div className="d-flex flex-row">
      <Tabs />
      <div className={`w-100`}>
        <div
          className="d-flex justify-content-between pt-3 pb-2"
          style={{
            background: "#FFFFFF",
            border: "1px solid #E2E2E2",
            borderRadius: "5px",
          }}
        >
          <div className="d-flex justify-content-end align-items-center ps-4">
            <div className={`main-heading me-sm-4 me-1`}>Watches</div>
            <button
              type="button"
              className="watch-btn pt-sm-1 pb-sm-1 ps-sm-3 pe-sm-3 ps-2"
              onClick={() => handleAdd()}
            >
              ADD A WATCH <img src={addIcon} alt="icon" />
            </button>
          </div>
          <Logout />
        </div>
        <div className={`container-fluid bg-${theme} p-2 main-layout`}>
          <div className="d-flex justify-content-end align-items-center pb-2">
            {checkedList.length > 0 ? (
              <>
                <button
                  onClick={handleDeleteAll}
                  className="d-none d-sm-block delete-btn ps-2"
                >
                  Delete <img src={deleteIcon} alt="icon" className="" />
                </button>
                <button
                  onClick={handleDeleteAll}
                  className="delete-btn d-block d-sm-none ps-2 text-center"
                >
                  <img src={deleteIcon} alt="icon" className="" />
                </button>
              </>
            ) : (
              <div className="me-2">
                <select
                  className="form-select watches-text"
                  value={pageStatus}
                  onChange={(e) => {
                    const newStatus = e.target.value;
                    setCheckedList([]);
                    setPage(1);
                    setPageStatus(newStatus);
                  }}
                  style={{
                    border: "1px solid #E2E2E2",
                    borderRadius: "3px",
                    padding: "6px 12px",
                    minWidth: "150px",
                    background: "#FFFFFF",
                  }}
                >
                  <option value="All Watches">All Watches</option>
                  <option value="Certified Watches">Certified Watches</option>
                  <option value="Uncertified Watches">
                    Not Certified Watches
                  </option>
                </select>
              </div>
            )}

            <div className="d-flex align-items-center watch-search-bar ps-2 ms-2">
              <input
                type="text"
                className="col-9"
                onChange={handleSearchInput}
                style={{ background: "none", border: "none" }}
                placeholder={`Search here... `}
              />
              <img
                className="input-group-text"
                src={search}
                alt=""
                style={{ background: "none", border: "none" }}
              />
            </div>
          </div>
          <div
            style={{
              background: "#FFFFFF",
              border: "1px solid #E2E2E2",
              borderRadius: "5px",
            }}
          >
            <div className="table-responsive main-layout3">
              <table className="table">
                <thead
                  className={`watches-heading  data-sticky-header-offset-y`}
                >
                  <tr>
                    <th>
                      <div>SN</div>
                    </th>
                    <th>
                      <div>WATCH</div>
                    </th>
                    <th>
                      <div
                        className="customButton"
                        onClick={handleAddedDateClicked}
                      >
                        ADDED DATE
                        <img
                          src={Sorticon}
                          alt="sort up"
                          className={`sort-icon ${
                            sortConfig.field === "createdAt" ? "active" : ""
                          }`}
                          style={{
                            width: "12px",
                            height: "12px",
                            transform: "rotate(180deg)",
                            opacity: sortConfig.field === "createdAt" ? 1 : 0.5,
                          }}
                        />
                      </div>
                    </th>
                    <th>
                      <div
                        className="customButton"
                        onClick={handleTransferDateClicked}
                      >
                        LAST TRANSFER DATE
                        <img
                          src={Sorticon}
                          alt="sort up"
                          className={`sort-icon ${
                            sortConfig.field === "last_trade_date"
                              ? "active"
                              : ""
                          }`}
                          style={{
                            width: "12px",
                            height: "12px",
                            transform: "rotate(180deg)",
                            opacity:
                              sortConfig.field === "last_trade_date" ? 1 : 0.5,
                          }}
                        />
                      </div>
                    </th>
                    <th>
                      <div>OWNER</div>
                    </th>
                    <th>
                      <div>STATUS</div>
                    </th>
                    <th>
                      <div>ACTIONS</div>
                    </th>
                  </tr>
                </thead>

                {status === STATUSES.IDLE && (
                  <>
                    {watchesData.watches && watchesData.watches.length > 0 && (
                      <tbody>{RenderItems(watchesData.watches)}</tbody>
                    )}
                  </>
                )}
              </table>
            </div>
            {status === STATUSES.LOADING ? (
              <div className="d-flex justify-content-center">
                <div className="spinner-border text-dark" role="status">
                  {/* <span className="visually-hidden">Loading...</span> */}
                </div>
              </div>
            ) : (
              watchesData.watches &&
              watchesData.watches.length < 1 && (
                <div className="d-flex justify-content-center">
                  <div
                    className="main-heading  pt-3"
                    style={{ width: "max-content" }}
                  >
                    No Data Found
                  </div>
                </div>
              )
            )}
            <div className="d-flex justify-content-between align-items-center ps-2 pe-2">
              {watchesData.watches?.length > 0 ? (
                <div className={`watches-heading`}>
                  Showing {(page - 1) * 10 + 1} to{" "}
                  {watchesData.watches?.length + (page - 1) * 10} of{" "}
                  {watchesData.totalItems} entries
                </div>
              ) : (
                <div className={`watches-heading`}>
                  Showing 0 to 0 of 0 entries
                </div>
              )}

              {watchesData.watches?.length > 0 && (
                <ReactPaginate
                  previousLabel={null}
                  nextLabel={">"}
                  onPageChange={handlePageClick}
                  //renderOnZeroPageCount={null}
                  //pageRangeDisplayed={2}
                  // marginPagesDisplayed={2}
                  pageCount={pageCount}
                  forcePage={page - 1}
                  containerClassName={"pagination p-1"}
                  pageClassName={`watches-heading m-1 d-flex justify-content-center align-items-center`}
                  nextLinkClassName={`watches-heading text-decoration-none`}
                  nextClassName={""}
                  disabledClassName={`watches-heading d-flex justify-content-center align-items-center`}
                  activeClassName={`page-active`}
                />
              )}
            </div>
          </div>

          <Add
            setAddButton={setAddButton}
            setShowModal={setShowModal}
            showModal={showModal}
          />

          <Delete
            setDeleteButton={setDeleteButton}
            id={deleteId}
            setShowModalDelete={setShowModalDelete}
            showModalDelete={showModalDelete}
          />
          <DeleteAll
            setDeleteButton={setDeleteButton}
            setCheckedList={setCheckedList}
            setShowModalDeleteAll={setShowModalDeleteAll}
            showModalDeleteAll={showModalDeleteAll}
            checkedList={checkedList}
            watchesData={watchesData}
          />
          {showModalUpdate && (
            <Edit
              setUpdateButton={setUpdateButton}
              id={updateId}
              setShowModalUpdate={setShowModalUpdate}
              showModalUpdate={showModalUpdate}
            />
          )}

          {openWatchForm && (
            <WatchDetail
              watch={selectedWatch}
              setMetaModalClose={() => {
                setSelectedWatch(null);
                setOpenWatchForm(false);
              }}
              readOnly={true}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Watches;
