import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { themeToggler } from "../../store/themeSlice";
import { fetchClients } from "../../store/clientsSlice";
import { STATUSES } from "../../store/clientsSlice";
import { menuBar } from "../../store/menuBarSlice";
import UpdateClient from "./update/update";
import AddUpdateClient from "./Add/AddUpdate";
import Dropdown from "react-bootstrap/Dropdown";
import ReactPaginate from "react-paginate";
import { imageURL } from "../../axios";
import Tabs from "../Tabs/tabs";
import "./clients.css";
import addIcon from "../../assets/images/Add_round.svg";
import Sorticon from "../../assets/images/sort.svg";
import menuIcon from "../../assets/images/menu.svg";
import editIcon from "../../assets/images/edit-icon.svg";
import user from "../../assets/images/person-icon.svg";
import search from "../../assets/images/Search_alt.svg";
import Logout from "../Logout/logout";
import DeleteClient from "./delete/delete";
import dateFormat from "dateformat";

function Clients() {
  const navigate = useNavigate();
  const [checkedAll, setCheckedAll] = useState(false);
  const [checkedList, setCheckedList] = useState([]);
  const [searchInput, setSearchInput] = useState("null");
  const [statusFilter, setStatusFilter] = useState("all"); // Added status filter state
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const { data: clientsData, status } = useSelector((state) => state.client);
  const [page, setPage] = useState(1);

  const [pageCount, setPageCount] = useState(1);
  const [updateId, setUpdateId] = useState({});
  const [deleteId, setDeleteId] = useState({});
  const [deleteButton, setDeleteButton] = useState(false);
  const [updateButton, setUpdateButton] = useState(false);
  const [addButton, setAddButton] = useState(false);
  const [showModalUpdate, setShowModalUpdate] = useState(false);
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [sortConfig, setSortConfig] = useState({
    field: "signinDate",
    direction: "asc",
  });

  useEffect(() => {
    dispatch(
      fetchClients({
        token,
        page,
        searchInput,
        sortConfig,
        filter: statusFilter === "all" ? null : statusFilter,
      })
    );
  }, [page, searchInput, sortConfig, token, dispatch, statusFilter]);

  useEffect(() => {
    if (status === STATUSES.IDLE && clientsData?.totalPages) {
      setPageCount(Math.ceil(clientsData.totalPages));
    }
  }, [clientsData, status]);

  useEffect(() => {
    if (updateButton || deleteButton || addButton) {
      setTimeout(() => {
        dispatch(
          fetchClients({
            token,
            page,
            searchInput,
            sortConfig,
            filter: statusFilter === "all" ? null : statusFilter,
          })
        );
        setPageCount(Math.ceil(clientsData?.totalPages));
      }, [2000]);
    }
  }, [deleteButton, updateButton, addButton]);

  const { theme } = useSelector((store) => store.theme);

  useEffect(() => {
    var mode = localStorage.getItem("mode");
    if (mode) {
      dispatch(themeToggler(mode));
    }
  }, []);

  if (status === STATUSES.ERROR) {
    return <div className="failed-text">Something went wrong!</div>;
  }

  const handleSignInClick = () => {
    setSortConfig((prev) => ({
      field: "signinDate",
      direction:
        prev.field === "signinDate"
          ? prev.direction === "asc"
            ? "desc"
            : "asc"
          : "desc",
    }));
  };

  const handleLastLoginClick = () => {
    setSortConfig((prev) => ({
      field: "lastLogin",
      direction:
        prev.field === "lastLogin"
          ? prev.direction === "asc"
            ? "desc"
            : "asc"
          : "desc",
    }));
  };

  function handlePageClick(data) {
    setCheckedAll(false);
    setCheckedList([]);
    setPage(data.selected + 1);
  }

  function handleSearchInput(e) {
    setCheckedAll(false);
    setCheckedList([]);
    setPage(1);
    if (e.target.value) {
      setSearchInput(e.target.value);
    } else {
      setSearchInput("null");
    }
  }

  const handleCheckboxChange = (id) => {
    if (checkedList.includes(id)) {
      setCheckedList(checkedList.filter((checkedId) => checkedId !== id));
    } else {
      setCheckedList([...checkedList, id]);
    }
  };

  const handleSelectAll = () => {
    const allIds = clientsData.clients.map((item) => item._id);
    setCheckedList(allIds);
    setCheckedAll(true);
  };

  function handleUpdate(_id, clientFirstName, clientLastName, email) {
    if (updateButton) setUpdateButton(false);
    setUpdateId({ _id, clientFirstName, clientLastName, email });
    setShowModalUpdate(true);
  }

  function handleDelete(email, first_name, last_name) {
    if (deleteButton) setDeleteButton(false);
    setDeleteId({ email, first_name, last_name });
    setShowModalDelete(true);
  }

  function handleAdd() {
    if (addButton) setAddButton(false);
    setShowModalAdd(true);
  }

  const RenderItems = (client) => {
    return client.map((data, index) => (
      <tr key={data._id}>
        <td className="p-2">
          <div className="d-flex">
            <div>{index + 1}</div>
          </div>
        </td>
        <td>
          <div>{data.clientFirstName + ` ` + data.clientLastName}</div>
        </td>
        <td>
          <div>{data.no_of_watches}</div>
        </td>
        <td>
          <div>{data.email}</div>
        </td>
        <td>
  <div>
    {data.signinDate 
      ? dateFormat(data.signinDate, "mmm dS, yyyy") 
      : "-"}
  </div>
</td>
<td>
  <div>
    {data.lastLogin
      ? dateFormat(data.lastLogin, "mmm dS, yyyy")
      : "-"}
  </div>
</td>
        <td>
          <div className="d-flex">
            {data.isActive === false ? (
              <div className={`watch-status ps-2 pe-2`}>Disabled</div>
            ) : data.verifiedUser === true ? (
              <div className={`watch-status ps-2 pe-2`}>Registered</div>
            ) : (
              <div className={`watch-status2 ps-2 pe-2`}>Not Registered</div>
            )}
          </div>
        </td>
        <td>
          <div>
            <Dropdown>
              <Dropdown.Toggle
                className="client-action-btn"
                id="dropdown-basic"
              >
                <img src={editIcon} alt="icon" className="p-1 me-2" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  className="clients-text"
                  onClick={() => navigate(`/watch?email=${data.email}`)}
                >
                  Detail
                </Dropdown.Item>
                <Dropdown.Item
                  className="clients-text"
                  onClick={() =>
                    handleUpdate(
                      data.serialNumber,
                      data.clientFirstName,
                      data.clientLastName,
                      data.email
                    )
                  }
                >
                  Edit
                </Dropdown.Item>
                {data.isActive === true && (
                  <Dropdown.Item
                    className="clients-text"
                    onClick={() =>
                      handleDelete(
                        data.email,
                        data.clientFirstName,
                        data.clientLastName
                      )
                    }
                  >
                    Delete
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </td>
      </tr>
    ));
  };

  return (
    <div className="d-flex flex-row">
      <Tabs />
      <div className={`w-100`}>
        <div
          className="d-flex justify-content-between pt-3 pb-2"
          style={{
            background: "#FFFFFF",
            border: "1px solid #E2E2E2",
            borderRadius: "5px",
          }}
        >
          <div className="d-flex justify-content-end align-items-center ps-4">
            <div className={`main-heading me-sm-4 me-1`}>Clients</div>
            <button
              type="button"
              className="watch-btn pt-sm-1 pb-sm-1 ps-sm-3 pe-sm-3 ps-2"
              onClick={() => handleAdd()}
            >
              ADD A CLIENT <img src={addIcon} alt="icon" />
            </button>
          </div>
          <Logout />
        </div>
        <div className={`container-fluid bg-${theme} p-2 main-layout`}>
          <div className="d-flex justify-content-end align-items-center pb-2">
            <div className="me-2">
              <select
                className="form-select clients-text"
                value={statusFilter}
                onChange={(e) => {
                  setStatusFilter(e.target.value);
                  setPage(1);
                }}
                style={{
                  border: "1px solid #E2E2E2",
                  borderRadius: "3px",
                  padding: "6px 12px",
                  minWidth: "150px",
                  background: "#FFFFFF",
                }}
              >
                <option value="all">All Status</option>
                <option value="registered">Registered</option>
                <option value="unregistered">Not Registered</option>
                <option value="disabled">Disabled</option>
              </select>
            </div>
            {/* Search Bar */}
            <div className="d-flex align-items-center watch-search-bar pe-2 me-2">
              <input
                type="text"
                className="col-9"
                onChange={handleSearchInput}
                style={{
                  background: "none",
                  border: "none",
                  marginLeft: "10px",
                }}
                placeholder={`Search here... `}
              />
              <img
                className="input-group-text"
                src={search}
                alt=""
                style={{ background: "none", border: "none" }}
              />
            </div>
          </div>

          <div
            className="mb-4"
            style={{
              background: "#FFFFFF",
              border: "1px solid #E2E2E2",
              borderRadius: "5px",
            }}
          >
            <div className="table-responsive main-layout3">
              <table className="table">
                <thead className={`clients-heading`}>
                  <tr>
                    <th>
                      <div className="d-flex">
                        <div className="pt-2">SN</div>
                      </div>
                    </th>
                    <th>
                      <div>CLIENTS</div>
                    </th>
                    <th>
                      <div>NO. OF WATCHES</div>
                    </th>
                    <th>
                      <div>EMAIL</div>
                    </th>
                    <th>
                      <div className="customButton" onClick={handleSignInClick}>
                        SIGN-UP
                        <img
                          src={Sorticon}
                          alt="sort up"
                          className={`sort-icon ${
                            sortConfig.field === "signinDate" ? "active" : ""
                          }`}
                          style={{
                            width: "12px",
                            height: "12px",
                            transform: "rotate(180deg)",
                            opacity:
                              sortConfig.field === "signinDate" ? 1 : 0.5,
                          }}
                        />
                      </div>
                    </th>
                    <th>
                      <div
                        className="customButton"
                        onClick={handleLastLoginClick}
                      >
                        LAST LOGIN
                        <img
                          src={Sorticon}
                          alt="sort up"
                          className={`sort-icon ${
                            sortConfig.field === "lastLogin" ? "active" : ""
                          }`}
                          style={{
                            width: "12px",
                            height: "12px",
                            transform: "rotate(180deg)",
                            opacity: sortConfig.field === "lastLogin" ? 1 : 0.5,
                          }}
                        />
                      </div>
                    </th>
                    <th>
                      <div>STATUS</div>
                    </th>
                    <th>
                      <div>ACTIONS</div>
                    </th>
                  </tr>
                </thead>
                {status === STATUSES.IDLE && (
                  <tbody className={`clients-text`}>
                    {clientsData.clients &&
                      clientsData.clients.length > 0 &&
                      RenderItems(clientsData.clients)}
                  </tbody>
                )}
              </table>
            </div>
            {status === STATUSES.LOADING ? (
              <div className="d-flex justify-content-center">
                <div className="spinner-border text-dark" role="status"></div>
              </div>
            ) : (
              clientsData.clients &&
              clientsData.clients.length < 1 && (
                <div className="d-flex justify-content-center">
                  <div
                    className="main-heading pt-3"
                    style={{ width: "max-content" }}
                  >
                    No Data Found
                  </div>
                </div>
              )
            )}
            <div className="d-flex justify-content-between align-items-center ps-2 pe-2 mt-1">
              {clientsData.clients?.length > 0 ? (
                <div className={`clients-heading`}>
                  Showing {(page - 1) * 10 + 1} to{" "}
                  {clientsData.clients?.length + (page - 1) * 10} of{" "}
                  {clientsData.totalItems} entries
                </div>
              ) : (
                <div className={`watches-heading`}>
                  Showing 0 to 0 of 0 entries
                </div>
              )}

              <ReactPaginate
                previousLabel={null}
                nextLabel={">"}
                onPageChange={handlePageClick}
                pageCount={pageCount}
                forcePage={page - 1}
                containerClassName={"pagination p-1"}
                pageClassName={`watches-heading m-1 d-flex justify-content-center align-items-center`}
                nextLinkClassName={`watches-heading text-decoration-none`}
                nextClassName={""}
                disabledClassName={`watches-heading d-flex justify-content-center align-items-center`}
                activeClassName={`page-active`}
              />
            </div>
          </div>
        </div>
      </div>
      <UpdateClient
        setUpdateButton={setUpdateButton}
        id={updateId}
        setShowModalUpdate={setShowModalUpdate}
        showModalUpdate={showModalUpdate}
      />
      <AddUpdateClient
        setUpdateButton={setAddButton}
        id={updateId}
        setShowModalUpdate={setShowModalAdd}
        showModalUpdate={showModalAdd}
      />
      <DeleteClient
        setDeleteButton={setDeleteButton}
        id={deleteId}
        setShowModalDelete={setShowModalDelete}
        showModalDelete={showModalDelete}
      />
    </div>
  );
}

export default Clients;
